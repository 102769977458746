/* Signup.css */

/* Basic reset for margins and paddings */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.form-container h2 {
  margin-bottom: 10px;
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.form-group input:focus {
  border-color: #80bdff;
  outline: none;
}

button,
.link-button {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  margin-top: 10px;
  text-align: center;
}

button {
  background-color: #28a745;
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #218838;
}

.link-button {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  color: black;
  text-decoration: none;
}

.link-button:hover {
  background-color: #e2e6ea;
}

p {
  margin-top: 10px;
  text-align: center;
}

.btn-next,
.btn-previous {
  background-color: #007bff;
  color: white;
}

.btn-next:hover,
.btn-previous:hover {
  background-color: #0056b3;
}

.btn-register {
  background-color: #28a745;
  color: white;
}

.btn-register:hover {
  background-color: #218838;
}

.error {
  color: red;
  font-size: 0.8em;
  margin-top: 5px;
}

.progress-bar {
  height: 5px;
  background-color: #007bff;
  margin-bottom: 15px;
  transition: width 0.3s ease;
}
