* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 25%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: fit-content;
}

.form-container h2 {
  margin-bottom: 10px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-weight: bold;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 0;
  margin-top: 5px;
}

.form-group input:focus {
  border-color: #80bdff;
  outline: none;
}

button,
.link-button {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 0;
  margin-top: 10px;
  text-align: center;
  text-decoration: none;
}

.btn-login {
  background-color: #28a745; /* Equivalent to Bootstrap btn-success */
  color: white;
  cursor: pointer;
}

.btn-login:hover {
  background-color: #218838;
}

.link-button {
  background-color: #f8f9fa; /* Equivalent to Bootstrap bg-light */
  border: 1px solid #ced4da; /* Equivalent to Bootstrap border */
  color: black;
}

.link-button:hover {
  background-color: #e2e6ea;
}

p {
  margin-top: 10px;
  text-align: center;
}
