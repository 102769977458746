body {
  background: linear-gradient(to right, #4f4d4d, #4d4b4b, #242020);
}

.graph-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.graph-container:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.title {
  font-size: 50px;
  margin-bottom: 20px;
  color: white;
  text-align: center;
  margin-top: 30px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  background-color: #0072ff;
  border-radius: 100%;
  animation: sk-bounce 2s infinite ease-in-out;
  transform: translate(-50%, -50%);
}

.double-bounce1,
.double-bounce2 {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-color: #0072ff;
  opacity: 0.6;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  animation-delay: -1s;
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

.container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.newtons-cradle {
  --uib-size: 200px;
  --uib-speed: 1.2s;
  --uib-color: #b8b7bd;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--uib-size);
  height: var(--uib-size);
}

.newtons-cradle__dot {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  width: 25%;
  transform-origin: center top;
}

.newtons-cradle__dot::after {
  content: "";
  display: block;
  width: 100%;
  height: 25%;
  border-radius: 50%;
  background-color: var(--uib-color);
}

.newtons-cradle__dot:first-child {
  animation: swing var(--uib-speed) linear infinite;
}

.newtons-cradle__dot:last-child {
  animation: swing2 var(--uib-speed) linear infinite;
}

@keyframes swing {
  0% {
    transform: rotate(0deg);
    animation-timing-function: ease-out;
  }
  25% {
    transform: rotate(70deg);
    animation-timing-function: ease-in;
  }
  50% {
    transform: rotate(0deg);
    animation-timing-function: linear;
  }
}

@keyframes swing2 {
  0% {
    transform: rotate(0deg);
    animation-timing-function: linear;
  }
  50% {
    transform: rotate(0deg);
    animation-timing-function: ease-out;
  }
  75% {
    transform: rotate(-70deg);
    animation-timing-function: ease-in;
  }
}

@media screen and (max-width: 500px) {
  .newtons-cradle {
    --uib-size: 150px;
  }
}

.chart-switcher {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.chart-switcher button {
  display: inline-block;
  margin: 0 5px; /* Add margin between buttons */
  padding: 5px 10px; /* Make buttons smaller */
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 12px; /* Adjust font size for smaller buttons */
}

.chart-switcher button:hover {
  background-color: #218838;
}

.refresh-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: auto;
}

.refresh-button:hover {
  background-color: #218838;
}
