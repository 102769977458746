* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  /*font-family: "Roboto Mono", monospace;*/
  font-family: "Roboto", sans-serif;
  background-color: #4f4d4d;
  color: black;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.table-container {
  width: 95%;
  height: auto;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-x: auto; /* Add horizontal scrolling for small screens */
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  cursor: pointer; /* Add cursor pointer to indicate sortability */
}

th {
  background-color: #4caf50;
  color: white;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #ddd;
}

.top-right {
  position: absolute;
  top: 20px; /* Adjust top position as needed */
  right: 20px; /* Adjust right position as needed */
}

.button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
}

.button:hover {
  background-color: #45a049;
}

.search-container {
  position: absolute;
  top: 150px;

  align-items: center;
}

.search-input {
  padding: 8px;
  padding-right: 100px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.search-input:focus {
  outline: none;
  border-color: #4caf50;
}

.refresh-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: auto;
}

.refresh-button:hover {
  background-color: #218838;
}
