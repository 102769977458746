.app-container {
  text-align: center;
  padding: 30px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 50px auto;
}

.app-container h1 {
  font-size: 2.5rem;
  color: #007bff;
  margin-bottom: 20px;
}

.app-container p {
  font-size: 1.2rem;
  color: #343a40;
  line-height: 1.6;
  margin-bottom: 30px;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.button-group .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.button-group .btn-primary {
  background-color: #007bff;
  color: white;
}

.button-group .btn-primary:hover {
  background-color: #0056b3;
}

.button-group .btn-secondary {
  background-color: #6c757d;
  color: white;
}

.button-group .btn-secondary:hover {
  background-color: #4d545a;
}

.icon {
  margin-right: 10px;
}

.popover {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  padding: 20px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 18px;
  color: #999;
}

.url-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.copy-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  margin-right: 10px;
}

.copy-button:hover {
  background-color: #0056b3;
}

.social-icons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.social-icons a {
  display: inline-block;
  margin: 0 10px;
  font-size: 24px;
  color: #007bff;
}

.social-icons a:hover {
  color: #0056b3;
}
